import { Icon } from '@innovigo/ui/components/Icon';
import { useTheme } from '@innovigo/ui/components/Theme/ThemeProvider';
import { useResponsiveStyle } from '@innovigo/ui/hooks/useResponseStyle';
import { Fragment } from 'react';
import { Pressable, ScrollView, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { ColorSchemeSwitch } from './ColorSchemeSwitch';

export function NavBar<TNavConfig>({
  containerStyle,
  logo,
  insets,
  margins,
  navLink,
  navLinkConfig,
}: {
  containerStyle?: StyleProp<ViewStyle>;
  logo?: React.ReactNode;
  insets?: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  margins?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  navLink: (props: TNavConfig) => React.ReactNode;
  navLinkConfig: ReadonlyArray<TNavConfig>;
}) {
  const theme = useTheme();
  const linkSpaceStyle = [
    styles.linkSpacer,
    useResponsiveStyle({
      mobile: {
        display: 'none',
      },
      tablet: {},
      desktop: {},
    }),
  ];

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: theme.background['01'],
          marginTop: margins?.top,
          marginRight: margins?.right,
          marginBottom: margins?.bottom,
          marginLeft: margins?.left,
        },
        useResponsiveStyle({
          mobile: {
            height: 76 + (insets?.bottom ?? 0),
            borderTopColor: theme.background['00'],
            borderTopWidth: 1,
            paddingRight: insets?.right,
            paddingBottom: insets?.bottom,
            paddingLeft: insets?.left,
          },
          tablet: {
            width: 96,
            paddingTop: Math.max(24, insets?.top ?? 0),
            paddingRight: 24,
            paddingBottom: Math.max(24, insets?.bottom ?? 0),
            paddingLeft: Math.max(24, insets?.left ?? 0),
          },
          desktop: {
            width: 340,
            paddingTop: Math.max(24, insets?.top ?? 0),
            paddingRight: 24,
            paddingBottom: Math.max(24, insets?.bottom ?? 0),
            paddingLeft: Math.max(24, insets?.left ?? 0),
          },
        }),
        containerStyle,
      ]}
    >
      <View
        style={[
          styles.logoContainer,
          useResponsiveStyle({
            mobile: {
              display: 'none',
            },
            tablet: {
              height: 48,
              width: 48,
              marginBottom: 16,
            },
            desktop: {
              height: 64,
              width: 64,
              marginBottom: 32,
            },
          }),
        ]}
      >
        {logo}
      </View>
      <ScrollView
        style={[styles.linkContainer]}
        contentContainerStyle={[
          useResponsiveStyle({
            mobile: {
              flexDirection: 'row',
              justifyContent: 'space-around',
            },
            tablet: {
              flexDirection: 'column',
              justifyContent: 'flex-start',
            },
            desktop: {
              flexDirection: 'column',
              justifyContent: 'flex-start',
            },
          }),
        ]}
      >
        {navLinkConfig.map((config, index) => {
          return (
            <Fragment key={index}>
              {navLink(config)}
              {index !== navLinkConfig.length - 1 ? <View style={linkSpaceStyle} /> : null}
            </Fragment>
          );
        })}
      </ScrollView>
      {/* <Pressable
        style={[
          styles.expandButton,
          useResponsiveStyle({
            mobile: {
              display: 'none',
            },
            tablet: {},
            desktop: {
              display: 'none',
            },
          }),
        ]}
      >
        <Icon name="arrow-right-alt" color={theme.neutral['04']} size={24} />
      </Pressable> */}
      <View
        style={[
          styles.bottomContainer,
          {
            borderTopColor: theme.neutral['03'],
            borderTopWidth: 2,
          },
          useResponsiveStyle({
            mobile: {
              display: 'none',
            },
            tablet: {},
            desktop: {},
          }),
        ]}
      >
        <View style={styles.helpContainer}></View>
        <ColorSchemeSwitch />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexShrink: 0,
  },
  logoContainer: {
    flexShrink: 0,
  },
  linkContainer: {
    flex: 1,
  },
  linkSpacer: {
    flexShrink: 0,
    height: 8,
  },
  expandButton: {
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    height: 48,
    width: 48,
  },
  bottomContainer: {
    flexShrink: 0,
    height: 64,
  },
  helpContainer: {
    flex: 1,
  },
});
